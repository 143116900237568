var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"Modal_empresas","tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h5',{staticClass:"modal-title text-white",attrs:{"id":"exampleModalLabel"}},[_vm._v(" Agregar Empresas ")]),_c('button',{ref:"closeModal",staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-8"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"descripcion"}},[_vm._v("Empresa")]),_c('v-select',{class:[
                  _vm.$v.form.empresa.$invalid ? 'is-invalid' : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"multiple":"","label":"razon_social","options":_vm.listasForms.lista_empresas,"placeholder":"Empresa"},model:{value:(_vm.form.empresa),callback:function ($$v) {_vm.$set(_vm.form, "empresa", $$v)},expression:"form.empresa"}})],1)]),_c('div',{staticClass:"form-group col-md-4"},[_c('div',{staticClass:"btn-group float-right"},[_c('button',{staticClass:"btn btn-success mt-4",attrs:{"type":"button"},on:{"click":function($event){return _vm.seleccionarEmpresas()}}},[_vm._v(" Seleccionar todas ")]),_c('button',{staticClass:"btn btn-danger mt-4",attrs:{"type":"button"},on:{"click":function($event){return _vm.deseleccionarEmpresas()}}},[_vm._v(" Quitar todas ")])])]),(!_vm.form.link_documento)?_c('div',{staticClass:"form-group col-md-9"},[_c('label',[_vm._v("Adjunto")]),_c('input',{staticClass:"form-control-file",attrs:{"type":"file","id":"archivo-adjunto-tarifario","accept":"image/x-png,image/gif,image/jpeg,application/pdf,"},on:{"change":_vm.obtenerArchivo}})]):_vm._e(),(_vm.fileAdjunto)?_c('div',{staticClass:"form-group col-md-3"},[_c('div',{staticClass:"btn-group float-left"},[_c('button',{staticClass:"btn btn-secondary col-md-12",attrs:{"type":"button"},on:{"click":_vm.limpiarArchivo}},[_c('i',{staticClass:"fas fa-eraser"})])])]):_vm._e(),(_vm.empresas.length > 0)?_c('div',{staticClass:"form-group col-md-12"},[_c('table',{staticClass:"table table-sm table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.empresas),function(empresa,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(empresa.fecha))]),_c('td',[_vm._v(_vm._s(empresa.razon_social))]),_c('td',[_vm._v(_vm._s(empresa.no_contrato))]),_c('td',[_c('div',{staticClass:"btn-group float-right"},[(empresa.link_documento)?_c('a',{staticClass:"btn btn-info btn-sm",attrs:{"target":"_blank","href":_vm.uri_docs + empresa.link_documento}},[_c('i',{staticClass:"fa fa-download"})]):_vm._e(),(
                          _vm.$store.getters.can(
                            'hidrocarburos.tarifarios.delete'
                          )
                        )?_c('button',{staticClass:"btn btn-sm btn-danger",staticStyle:{"float":"right"},on:{"click":function($event){return _vm.save(empresa)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()])])])}),0)])]):_vm._e()])]),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Cerrar ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.$store.getters.can('hidrocarburos.tarifarios.edit') &&
              !_vm.$v.form.$invalid &&
              _vm.fileAdjunto
          ),expression:"\n            $store.getters.can('hidrocarburos.tarifarios.edit') &&\n              !$v.form.$invalid &&\n              fileAdjunto\n          "}],staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.save(_vm.form.empresa, 'guardar')}}},[_vm._v(" Guardar ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',[_vm._v("Fecha")]),_c('th',[_vm._v("Empresa")]),_c('th',[_vm._v("# Contrato")]),_c('th',{staticStyle:{"width":"10%"}},[_vm._v("Acciones")])])])
}]

export { render, staticRenderFns }