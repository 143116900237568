<template>
  <div
    class="modal fade"
    id="Modal_empresas"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-frontera-top-left pt-2 pb-2">
          <h5 class="modal-title text-white" id="exampleModalLabel">
            Agregar Empresas
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            ref="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="form-group col-md-8">
              <div class="form-group">
                <label for="descripcion">Empresa</label>
                <v-select
                  :class="[
                    $v.form.empresa.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.empresa"
                  multiple
                  label="razon_social"
                  :options="listasForms.lista_empresas"
                  placeholder="Empresa"
                ></v-select>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div class="btn-group float-right">
                <button
                  type="button"
                  class="btn btn-success mt-4"
                  @click="seleccionarEmpresas()"
                >
                  Seleccionar todas
                </button>
                <button
                  type="button"
                  class="btn btn-danger mt-4"
                  @click="deseleccionarEmpresas()"
                >
                  Quitar todas
                </button>
              </div>
            </div>
            <div class="form-group col-md-9" v-if="!form.link_documento">
              <label>Adjunto</label>
              <input
                type="file"
                id="archivo-adjunto-tarifario"
                class="form-control-file"
                accept="image/x-png,image/gif,image/jpeg,application/pdf,"
                @change="obtenerArchivo"
              />
            </div>
            <div class="form-group col-md-3" v-if="fileAdjunto">
              <div class="btn-group float-left">
                <button
                  type="button"
                  class="btn btn-secondary col-md-12"
                  @click="limpiarArchivo"
                >
                  <i class="fas fa-eraser"></i>
                </button>
              </div>
            </div>
            <div class="form-group col-md-12" v-if="empresas.length > 0">
              <table class="table table-sm table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th>Fecha</th>
                    <th>Empresa</th>
                    <th># Contrato</th>
                    <th style="width: 10%">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(empresa, index) in empresas" :key="index">
                    <td>{{ empresa.fecha }}</td>
                    <td>{{ empresa.razon_social }}</td>
                    <td>{{ empresa.no_contrato }}</td>
                    <td>
                      <div class="btn-group float-right">
                        <a
                          target="_blank"
                          class="btn btn-info btn-sm"
                          v-if="empresa.link_documento"
                          :href="uri_docs + empresa.link_documento"
                        >
                          <i class="fa fa-download"></i>
                        </a>
                        <button
                          class="btn btn-sm btn-danger"
                          style="float: right"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.tarifarios.delete'
                            )
                          "
                          @click="save(empresa)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            v-show="
              $store.getters.can('hidrocarburos.tarifarios.edit') &&
                !$v.form.$invalid &&
                fileAdjunto
            "
            @click="save(form.empresa, 'guardar')"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TarifarioAsignarEmpresa",
  components: {
    vSelect,
  },
  data() {
    return {
      id: null,
      empresas: [],
      contratos: [],
      fileAdjunto: null,
      form: {
        empresa: null,
        link_documento: null,
      },
      listasForms: {
        lista_empresas: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    form: {
      empresa: {
        required,
      },
    },
  },
  methods: {
    async getIndex() {
      this.id = this.$route.params.data_edit.id;
      await axios
        .get("/api/hidrocarburos/tarifariosEmpresas/", {
          params: { id: this.id },
        })
        .then((response) => {
          this.getEmpresasAsigandas(response.data[0].contratos);
          this.getEmpresas();
        });
    },

    getEmpresasAsigandas(contratos) {
      contratos.forEach((element) => {
        this.empresas.push({
          contrato_id: element.id,
          no_contrato: element.no_contrato,
          tarifario_id: this.id,
          razon_social: element.empresa.razon_social,
          link_documento: element.pivot.link_documento,
          fecha: moment(element.pivot.created_at).format("YYYY-MM-DD"),
        });
      });
    },

    // Se buscan los datos para precargar las tatifas existentes en el tarifario
    getEmpresas() {
      this.listasForms.lista_empresas = [];
      let me = this;
      var url = "api/hidrocarburos/buscarEmpresasTarifario";
      axios
        .get(url, {
          params: {
            tarifario_id: me.id,
          },
        })
        .then(function(response) {
          response.data.forEach((data) => {
            me.listasForms.lista_empresas.push({
              id: data.empresa_id,
              contrato_id: data.contrato_id,
              tarifario_id: me.id,
              razon_social: `${data.no_contrato} - ${data.razon_social}`,
            });
          });
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarEmpresas() {
      this.form.empresa = this.listasForms.lista_empresas;
    },

    deseleccionarEmpresas() {
      this.form.empresa = null;
    },

    limpiarArchivo() {
      this.fileAdjunto = null;
      document.getElementById("archivo-adjunto-tarifario").value = "";
    },

    obtenerArchivo(e) {
      this.fileAdjunto = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileAdjunto = file;
        }
      } else {
        this.archivoValido = true;
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 1000000) {
        flag = false;
        this.limpiarArchivo();
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo, seleccione un archivo de nuevo...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    async save(array = null, accion = null) {
      let title = null;
      let formData = new FormData();
      formData.append("accion", accion);
      formData.append("empresas", JSON.stringify(array));
      formData.append("link_documento", this.fileAdjunto);

      //   Se filtra el mensaje de respuesta
      if (accion) {
        title = "La empresa se guardó exitosamente...";
      } else {
        title = "La empresa se eliminó exitosamente...";
      }
      await axios
        .post(
          "/api/hidrocarburos/tarifarios/assingOrRevokeEmpresa/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.$refs.closeModal.click();
          this.form.empresa = null;
          this.listasForms.lista_empresas = [];
          this.lista_empresas = [];
          this.empresas_asignadas = [];
          this.empresas = [];
          this.getIndex();
          this.limpiarArchivo();

          this.$swal({
            icon: "success",
            title,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
  mounted() {
    this.getIndex();
    this.getEmpresas();
  },
};
</script>
